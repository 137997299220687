import { FilterManager, FilterOption, FilterState } from "./filterManager";
import * as Sentry from "@sentry/nextjs";

export type PaginatedResponse<T> = {
  items: Array<T>;
  next_cursor?: string;
  prev_cursor?: string;
};

export type ProfileSearchArgs = {
  filters?: FamSearchPayload;
  cursor?: string;
  limit?: number;
};

export type ProfileNotFoundError = {
  type: "profileNotFound";
};

export type MinimalArchetype = {
  uuid: string;
  name: string;
};

export type ProfileQuestion = {
  name: string;
};

export type ProfileAnswer = {
  answer: string;
  question: ProfileQuestion;
  adoptive_parent: MinimalAdoptiveParentInformation;
};

export type ProfileMedia = {
  blob_key: string;
  media_url: string;
  generation_id: number;
  content_type: string;
} & ConfigurableMediaProperties;

export type MinimalAdoptiveParentInformation = {
  uuid: string;
  first_name: string;
  archetype?: MinimalArchetype;
};

export type AdoptiveParentInformation = MinimalAdoptiveParentInformation & {
  last_name?: string;
  religion: Religion;
  education: Education;
  profession: string;
  political_affiliation: PoliticalAffiliation;
  age: number;
  show_age: boolean;
  gender: string;
  answers: ProfileAnswer[];
};

type AccountBase = {
  uuid: string;
  slug: string;
};

type BasePublicAdoptiveParentProfile = AccountBase & {
  banner_photo?: ProfileMedia;
};

export type MinimalPublicAdoptiveParentProfile =
  BasePublicAdoptiveParentProfile & {
    adoptive_parents: MinimalAdoptiveParentInformation[];
    random_answer?: ProfileAnswer;
    home_study_verified?: boolean;
    partner_professionals?: MinimalPublicProfessional[];
  };

export type PublicAdoptiveParentProfile = BasePublicAdoptiveParentProfile & {
  profile_book?: ProfileMedia;
  photos: ProfileMedia[];
  video_url?: string;
  family_structure?: FamilyStructure;
  site_urls: SiteUrls;
  state?: string;
  adoptive_parents: AdoptiveParentInformation[];
  welcome_message?: string;
  home_study_verified?: boolean;
  partner_professionals?: MinimalPublicProfessional[];
};

export type SiteUrls = {
  personal_site?: string;
  instagram_url?: string;
  facebook_url?: string;
  twitter_url?: string;
  tiktok_url?: string;
};

export type Religion = (typeof religionOptions)[number];
export type Education = (typeof educationOptions)[number];
export type PoliticalAffiliation = (typeof politicalAffiliationOptions)[number];
export type FamilyStructure = (typeof familyStructureOptions)[number];
export type RaceEthnicity = (typeof raceEthnicityOptions)[number];

export type FamSearchPayload = {
  family_structure?: string[];
  state?: string[];
  race_ethnicity?: string[];
  religion?: string[];
  archetype?: string[]; // uuids
  education?: string[];
  political_affiliation?: string[];
  open_to_special_needs?: boolean;
  open_to_multiple_children?: boolean;
  adoption_relationship_preference?: string[];
  approved_home_study?: boolean;
  family_structure_exclusions?: string[];
};

export function transcribeFamSearchFilters(
  filters: FilterState,
): FamSearchPayload | undefined {
  const filterManager = new FilterManager(filters);
  const payload = {} as FamSearchPayload;
  let sendPayload = false;

  // make sure categories are appropriaite for the payload
  for (const option of filterManager.getSelectedOptions()) {
    const category = option.category;
    /* letting this go through so we get sentry logging when there's something
     * desynced
    if (!(isFamSearchKey(category) || category === "open_to")) {
      continue;
    }
    */
    sendPayload = true;
    switch (category) {
      // keys with exclusions:
      // these intentionally fall through if they are not exclusions
      case "family_structure":
        const maybeExclusion = tryExclusion(category, option);
        if (maybeExclusion) {
          const exclusionCategory = exclusionMap[category];
          const exclusionOptions = payload[exclusionCategory];
          const exclusionValue = maybeExclusion.optionValue;
          if (exclusionOptions !== undefined) {
            exclusionOptions.push(exclusionValue);
          } else {
            payload[exclusionCategory] = [exclusionValue];
          }
          break;
        }
      // keys without exclusions
      case "archetype":
      case "education":
      case "race_ethnicity":
      case "religion":
      case "political_affiliation":
      case "adoption_relationship_preference":
      case "state":
        const options = payload[category];
        if (options !== undefined) {
          options.push(option.value);
        } else {
          payload[category] = [option.value];
        }
        break;
      // handle booleans separately - they might be represented as their own
      // category, but more likely they'll be combined in some fashion
      case "open_to_multiple_children":
      case "open_to_special_needs":
        payload[category] = true;
        break;
      case "approved_home_study":
        payload[category] = true;
        break;
      case "open_to":
        const openToVal: OpenToType = option.value as OpenToType;
        switch (openToVal) {
          case "Special Needs":
            payload.open_to_special_needs = true;
            break;
          case "Multiple Children":
            payload.open_to_multiple_children = true;
            break;
        }
        break;
      default:
        Sentry.captureException(
          new Error(
            `No behavior specified for FamSearchPayload key: ${category}; ignoring.`,
          ),
        );
    }
  }

  if (sendPayload) {
    return payload;
  }
}

type ExcludableKey = Extract<keyof FamSearchPayload, "family_structure">;
type ExclusionKey = Extract<
  keyof FamSearchPayload,
  "family_structure_exclusions"
>;

// map between "normal" payload keys and their corresponding exclusion keys
const exclusionMap: Record<ExcludableKey, ExclusionKey> = {
  family_structure: "family_structure_exclusions",
};

type FilterExclusion = {
  category: ExcludableKey;
  apiCategory: ExclusionKey;
  optionDisplay: string;
  optionValue: string;
};

export const exclusions: Array<FilterExclusion> = [
  {
    category: "family_structure",
    apiCategory: "family_structure_exclusions",
    optionDisplay: "No Children",
    optionValue: "With Children",
  },
];

function tryExclusion(
  category: ExcludableKey,
  option: FilterOption,
): FilterExclusion | false {
  const exclusion = exclusions.find(
    (item) =>
      item.optionDisplay === option.displayName && item.category === category,
  );

  return exclusion ?? false;
}

export const educationOptions = [
  "Some College",
  "Master’s Degree", // weird apostrophe char
  "Ph.D. or higher",
  "Bachelor’s Degree", // weird apostrophe char
  "Some High School",
  "High School",
  "Trade School",
  "Prefer not to say",
] as const;

export const familyStructureOptions = [
  "Stay-at-Home Parent",
  "With Children",
  "No Children",
  "Married",
  "Single Parent Family",
  "LGBT+ Family",
] as const;

export const raceEthnicityOptions = [
  "Native Hawaiian or Other Pacific Islander",
  "Caucasian",
  "Asian",
  "Black or African American",
  "American Indian or Alaska Native",
  "Hispanic or Latino",
  //"Open to all races", implicitly true if no selection
] as const;

export const religionOptions = [
  "None",
  "Christianity",
  "Buddhism",
  "Hinduism",
  "Islam",
  "Judaism",
  "Church of Jesus Christ of LDS",
  "Other",
  "Catholicism",
  "Catholicism/Christianity",
  "Prefer not to say",
  "Spiritual",
] as const;

export const politicalAffiliationOptions = [
  "Very Conservative",
  "Lean Conservative",
  "Lean Right",
  "Independent",
  "Lean Left",
  "Lean Liberal",
  "Very Liberal",
  "Not Political",
] as const;

export const professionalTypeOptions = [
  "Agency",
  "Attorney",
  "Home Study Provider",
  // "Social Worker",
  // "Consultant",
  // "Therapist",
] as const;

export const states = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC", // washington D.C.
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
] as const;

export const stateDisplayNames: Record<State, string> = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "Washington D.C.",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const professionalServiceOptions = [
  "home_study",
  "matching",
  "placement",
  "support",
] as const;

export const serviceDisplayNames: Record<ProfessionalService, string> = {
  home_study: "Home Study",
  matching: "Matching",
  support: "Education & Support",
  placement: "Placement",
};

export const relationshipPreferences = [
  "Very Open",
  "Moderately Open",
  "Closed",
] as const;

export const homeStudyOptions = ["Approved"] as const;

export const openToOptions = ["Special Needs", "Multiple Children"] as const;

export type ProfessionalType = (typeof professionalTypeOptions)[number];
export type OpenToType = (typeof openToOptions)[number];
export type relationshipPreferences = (typeof relationshipPreferences)[number];
export type State = (typeof states)[number];
export type ProfessionalService = (typeof professionalServiceOptions)[number];

export function isState(candidate: string): candidate is State {
  return states.indexOf(candidate as State) > -1;
}

export type MinimalPublicProfessional = {
  uuid: string;
  organization_name: string;
  type: ProfessionalType;

  contact_first_name?: string;
  contact_last_name?: string;

  city?: string;
  state?: string;

  website?: string;
  email_address?: string;
  phone?: string;
};

export type MinimalPublicOrg = {
  uuid: string;
  name: string;
  description?: string;
  website?: string;
  contact_email: string;
  contact_phone: string;
  is_published: boolean;
  city?: string;
  states?: string[];
  services: string[];
  org_type: string;
};

export type ProfessionalSearchPayload = {
  state?: State[];
  type?: ProfessionalType[];
};

export type PublicProfessional = {
  uuid: string;
  organization_name?: string;
  contact_first_name?: string;
  contact_last_name?: string;
  type?: ProfessionalType;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  phone?: string;
  fax?: string;
  website?: string;
  profile_link?: string;
  email_address?: string;
  practice_areas?: string;
  practice_locations?: string;
  amount_served?: string;
  primary_service?: string;
  is_listed?: string;
  is_confirmed?: string;
  more_info?: string;
  other_states_served?: string;
};

export type PricingResponse = {
  complete: Array<ProductPricingResponse>;
  connect_annual: Array<ProductPricingResponse>;
  connect_monthly: Array<ProductPricingResponse>;
  connect_plus: Array<ProductPricingResponse>;
  home_study: Array<ProductPricingResponse>;
};

export type ProductPricingResponse =
  | RecurringProductPricingResponse
  | OneTimeProductPricingResponse;
export type BaseProductPricingResponse = {
  unit_amount: number;
  internalId: string;
  id: string;
  product: {
    name: string;
  };
};
export type RecurringProductPricingResponse = BaseProductPricingResponse & {
  type: "recurring";
  recurring: {
    interval: "year" | "month";
  };
};
export type OneTimeProductPricingResponse = BaseProductPricingResponse & {
  type: "one_time";
};

export type CreateCheckoutSessionResponse = {
  checkout_url: string;
};
export type GetCheckoutSessionResultResponse = {
  id: string; // checkout session Id
  object: "checkout.session";
  payment_status: "paid" | "unpaid" | "no_payment_required";
};

export type AccountGroup = "fam" | "pro" | "bio" | "ref";
export type AccountStatus =
  | "stub"
  | "pending"
  | "inactive"
  | "active"
  | "canceled"
  | "banned"
  | "expired"
  | "trialing";

export type ConfigurableMediaProperties = {
  description?: string;
  sort_order: number;
  active: boolean;
  private: boolean;
} & (
  | {
      original_blob_key: string;
      cropped: true;
      cropped_dimensions: [number, number, number, number];
    }
  | {
      original_blob_key?: undefined;
      cropped: false;
      cropped_dimensions?: undefined;
    }
);

export type UpdateMediaPayload = {
  blob_key: string;
} & ConfigurableMediaProperties;

export type HasHomeStudy = "yes" | "no" | "in_progress";

export type UpdateAdoptiveFamilyAccountPreferences = {
  has_home_study?: HasHomeStudy;
  home_study_expiration?: string; // iso datetime
  home_study?: UpdateMediaPayload;

  // TODO There is a wealth of Connect speceific fields
  // that we will add here once we need them to
  // rebuild the Connect UI in React.
};

export type UpdateAccountPayload = {
  product_interest?: ProductInterest[];
  adoptive_family_account_preferences?: UpdateAdoptiveFamilyAccountPreferences;
};

export type ConfidentialAdoptiveParentInformation = {
  date_of_birth?: string; // iso datetime
  is_confidential: true;
} & AdoptiveParentInformation;

export type ConfidentialAdoptiveFamilyProfile = {
  group: "fam";
  adoptive_parents: ConfidentialAdoptiveParentInformation[];
  home_study?: ProfileMedia;
  has_home_study?: HasHomeStudy;
  home_study_expiration?: string; // iso datetime
} & PublicAdoptiveParentProfile;

export type ProductInterest =
  | "home_study"
  | "connect"
  | "complete"
  | "add_listing"
  | "resources";
export type ConfidentialExpectantMomProfile = {
  group: "bio";
  state: State;
  first_name: string | null;
  // There are more fields in the API, but we don't need them yet.
};

export type ConfidentialProfessionalProfile = {
  group: "pro";
  postal_code: string;
  state: State;
  first_name: string;
  last_name: string | null;
};

export type ConfidentialAccount = {
  uuid: string;
  slug: string;
  email: string;
  group: AccountGroup;
  status: AccountStatus;
  profile:
    | ConfidentialAdoptiveFamilyProfile
    | ConfidentialExpectantMomProfile
    | ConfidentialProfessionalProfile;
  product_interest?: ProductInterest[];
};

export type RegisterPayload = {
  token: string;
  account_group: AccountGroup;
  biological_parent?: CreateBiologicalParentPayload;
  adoptive_parent?: CreateAdoptiveParentPayload;
  professional?: CreateProfessionalPayload;
};

export type CreateBiologicalParentPayload = {
  first_name: string;
  state?: string;
};

export type CreateAdoptiveParentPayload = {
  first_name: string;
  last_name?: string;
  postal_code?: string;
};

export type CreateProfessionalPayload = {
  first_name: string;
  last_name?: string;
  postal_code: string;
};

// A type that matches AccountGroup:
// export type AccountGroup = "fam" | "pro" | "bio" | "ref";
// to Creation payload.
export type AccountGroupCreationPayloadMap = {
  [K in AccountGroup]: K extends "fam"
    ? CreateAdoptiveParentPayload
    : K extends "pro"
      ? CreateProfessionalPayload
      : K extends "bio"
        ? CreateBiologicalParentPayload
        : never;
};
