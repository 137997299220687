import Link from "next/link";
import { ReactNode, useEffect, useState } from "react";
import { getClassName } from "@pairtreefamily/utils";
import { getStateQueryString } from "../utils/getStateQueryString";
import { ButtonColor, ButtonStyle, getBaseButtonStyle } from "./button";
import { usePosthog } from "@pairtreefamily/ui";

// convenience wrapper around Next.js's `Link` for known routes
export namespace LinkTo {
  type LinkStyle = "text" | "button";

  type BaseLinkToProps = {
    style?: LinkStyle;
    children: ReactNode;
    className?: string;
    dataTest?: string;
  };

  type AnchorLinkProps = BaseLinkToProps & {
    style?: "text";
  };

  type ButtonLinkProps = BaseLinkToProps & {
    style?: "button";
    buttonColor?: ButtonColor;
    buttonStyle?: ButtonStyle;
  };

  type LinkTarget = "_self" | "_blank" | "_parent" | "_top";

  // LinkToProps is a discrimated union over the different types of links. We
  // don't necessarily have the same configuration options for each type
  export type LinkToProps = AnchorLinkProps | ButtonLinkProps;

  export type AccountGroup = "pro" | "fam" | "bio";

  export function LinkTo(
    props: LinkToProps & {
      href?: string;
      target?: LinkTarget;
      onClick?: () => void;
    },
  ) {
    let className = props.className;
    if (props.style === "button") {
      className = getClassName(
        className,
        getBaseButtonStyle(props.buttonColor, props.buttonStyle),
      );
    }

    // if the destination is external, we just use a plain anchor tag
    if (props.href) {
      if (props.href[0] !== "/") {
        return (
          <a
            href={props.href}
            target={props.target}
            className={className}
            data-test={props.dataTest}
            onClick={props.onClick}
          >
            {props.children}
          </a>
        );
      }
      return (
        <Link
          href={props.href}
          className={className}
          data-test={props.dataTest}
          onClick={props.onClick}
        >
          {props.children}
        </Link>
      );
    }
    return (
      <div
        className={getClassName(className, "cursor-pointer")}
        data-test={props.dataTest}
        onClick={props.onClick}
      >
        {props.children}
      </div>
    );
  }

  export type LinkToWithSlugProps = LinkToProps & {
    slug?: string;
  };

  function addSlug(slug?: string): string {
    if (!slug) {
      return "";
    }

    return `/${slug}`;
  }

  export const subdomains = {
    solutions: "solutions",
    services: "services",
    learning: "learning",
    resources: "resources",
    families: "waiting-families",
    pricing: "plansAndFees",
  };

  export function Home(props: LinkToProps) {
    return <LinkTo {...props} href="/" />;
  }

  export function WaitingFamilies(props: LinkToProps & { slug?: string }) {
    const queryString = props.slug ? `/${props.slug}` : "";
    return <LinkTo {...props} href={`/${subdomains.families}${queryString}`} />;
  }

  export function ExpectantMothersSolution(props: LinkToProps) {
    return (
      <LinkTo {...props} href={`/${subdomains.solutions}/expectantMothers`} />
    );
  }

  export function AdoptiveFamiliesSolution(props: LinkToProps) {
    return (
      <LinkTo {...props} href={`/${subdomains.solutions}/adoptiveFamilies`} />
    );
  }

  export function AdoptionProfessionalsSolution(props: LinkToProps) {
    return (
      <LinkTo
        {...props}
        href={`/${subdomains.solutions}/adoptionProfessionals`}
      />
    );
  }

  export function EmbryoDonorsSolution(props: LinkToProps) {
    return <LinkTo {...props} href={`/${subdomains.solutions}/embryoDonors`} />;
  }

  export function Resources(props: LinkToProps & { state?: string }) {
    return (
      <LinkTo
        {...props}
        href={`/${subdomains.resources}${getStateQueryString(props.state)}`}
      />
    );
  }

  export function AdoptionProfessionalsResource(
    props: LinkToProps & { state?: string },
  ) {
    return (
      <LinkTo
        {...props}
        href={`/${
          subdomains.resources
        }/adoptionProfessionals${getStateQueryString(props.state)}`}
      />
    );
  }

  export function Pricing(props: LinkToProps & { state?: string }) {
    return <LinkTo {...props} href={`/${subdomains.pricing}`} />;
  }

  export function Learning(props: LinkToProps) {
    return <LinkTo {...props} href={`/${subdomains.learning}`} />;
  }

  export function Insights(props: LinkToWithSlugProps) {
    return (
      <LinkTo
        {...props}
        href={`/${subdomains.learning}/adoption-blog${addSlug(props.slug)}`}
      />
    );
  }

  export function Events(props: LinkToWithSlugProps) {
    return (
      <LinkTo
        {...props}
        href={`/${subdomains.learning}/events${addSlug(props.slug)}`}
      />
    );
  }

  export function HowTos(props: LinkToWithSlugProps) {
    return (
      <LinkTo
        {...props}
        href={`/${subdomains.learning}/howTos${addSlug(props.slug)}`}
      />
    );
  }

  export function HomeStudy(props: LinkToProps) {
    return <LinkTo {...props} href={`/${subdomains.services}/homeStudy`} />;
  }

  export function Matching(props: LinkToProps) {
    return <LinkTo {...props} href={`/${subdomains.services}/matching`} />;
  }

  //@TODO: Update destination once created.
  export function Licensure(props: LinkToProps) {
    return (
      <LinkTo
        {...props}
        href={`https://landing.pairtreefamily.com/pairtree-home-study-social-workers`}
      />
    );
  }

  //@TODO: Update destination once created.
  export function Legal(props: LinkToProps) {
    // TODO v1.1 reroute to actual legal page
    return <AdoptionProfessionalsResource {...props} />;
    //return <LinkTo {...props} href={`/${subdomains.resources}/`} />;
  }

  export function Privacy(props: LinkToProps) {
    return <LinkTo {...props} href={`/privacy`} />;
  }

  export function AboutUsPage(
    props: LinkToProps & {
      sectionId: "" | "we-believe" | "giving-back" | "team" | "press";
    },
  ) {
    return (
      <LinkTo
        {...props}
        href={`/aboutUs${props.sectionId && `#${props.sectionId}`}`}
      />
    );
  }

  export function Terms(props: LinkToProps) {
    return <LinkTo {...props} href={`/terms-and-conditions`} />;
  }

  // take the user to sign up/in to the Flutter client
  export function PairTreeApp(props: LinkToProps & { group?: AccountGroup }) {
    const posthog = usePosthog();

    const [showDreamcatcher, setShowDreamcatcher] = useState<boolean>(false);

    useEffect(() => {
      if (posthog.enabledFeatureFlags["dreamcatcher-platform"]) {
        setShowDreamcatcher(true);
      } else {
        setShowDreamcatcher(false);
      }
    }, [posthog.enabledFeatureFlags]);

    if (showDreamcatcher) {
      return <Platform {...props} />;
    }

    return (
      <LinkTo
        {...props}
        href={`${process.env.NEXT_PUBLIC_PAIRTREE_APP_URL ?? "/"}${
          props.group ? `?group=${props.group}` : ""
        }`}
      />
    );
  }

  export function Platform(props: LinkToProps & { group?: AccountGroup }) {
    return (
      <LinkTo
        {...props}
        href={`${"https://platform.pairtreefamily.com/"}${
          props.group ? `?group=${props.group}` : ""
        }`}
      />
    );
  }

  export function External(
    props: LinkToProps & { url: string; self?: boolean },
  ) {
    return (
      <LinkTo
        {...props}
        href={props.url}
        target={props.self ? "_self" : "_blank"}
      />
    );
  }
}
