import { ParsedUrlQuery } from "querystring";

// types
export type FilterState = {
  categories: {
    [key: string]: FilterCategory;
  };
  options: {
    [key: string]: FilterOption;
  };
};

export type FilterCategory = {
  value: string;
  displayName: string;
};

export type FilterOption = {
  value: string;
  displayName: string;
  category: string;
  selected?: boolean;
};

// class
// Utility class for managing interactions with FilterState objects
export class FilterManager {
  state: FilterState;

  constructor(state?: FilterState) {
    if (state) {
      this.state = state;
    } else {
      this.state = {
        categories: {},
        options: {},
      };
    }
  }

  getState(): FilterState {
    return this.state;
  }

  getOptions(category?: string): FilterOption[] {
    return Object.values(this.state.options).filter((o) =>
      category ? o.category === category : true,
    );
  }

  getSelectedOptions(category?: string): FilterOption[] {
    return this.getOptions(category).filter((o) => o.selected);
  }

  countOptionsSelected(category?: string): number {
    return this.getSelectedOptions(category).length;
  }

  getCategories(): FilterCategory[] {
    return Object.values(this.state.categories);
  }

  hasCategory(category: string): boolean {
    return this.getCategories().findIndex((c) => c.value === category) > -1;
  }

  // TODO maybe remove
  getSelectedCategoryValues(): string[] {
    const categorySet = new Set<string>(
      this.getSelectedOptions().map((o) => o.category),
    );

    return [...categorySet];
  }

  // TODO maybe remove
  addOption(option: FilterOption): FilterManager {
    this.upsertOption(option);
    return this;
  }

  upsertOption(option: FilterOption): FilterManager {
    this.state.options[option.value] = option;
    return this;
  }

  getOption(optionValue: string): FilterOption | null {
    const option = this.state.options[optionValue];
    if (!option) {
      console.log(`no filter option with value '${optionValue}' found.`);
      // TODO sentry
      return null;
    }
    return option;
  }

  setOptionSelection(optionValue: string, selected: boolean): FilterManager {
    const option = this.getOption(optionValue);
    if (!option) {
      // TODO sentry
      return this;
    }

    this.state.options[optionValue] = { ...option, selected: selected };
    return this;
  }

  toggleOptionSelection(optionValue: string): FilterManager {
    const option = this.getOption(optionValue);
    if (!option) {
      // TODO sentry
      return this;
    }

    this.setOptionSelection(optionValue, !option.selected);
    return this;
  }

  clearFilters(category?: string): FilterManager {
    for (const option of this.getSelectedOptions(category)) {
      this.setOptionSelection(option.value, false);
    }

    return this;
  }

  addCategoryOptions<T>(args: {
    categoryValue: string;
    categoryDisplayName: string;
    items: T[];
    getValue: (item: T) => string;
    getDisplayName: (item: T) => string;
  }): FilterManager {
    const {
      categoryValue,
      categoryDisplayName,
      items,
      getValue,
      getDisplayName,
    } = args;

    this.state.categories[categoryValue] = {
      displayName: categoryDisplayName,
      value: categoryValue,
    };

    for (const datum of items) {
      const value = getValue(datum);
      this.state.options[value] = {
        value: value,
        displayName: getDisplayName(datum),
        category: categoryValue,
      };
    }

    return this;
  }

  // serialize filter tree to queryParam string
  serialize(): string {
    const params = new URLSearchParams();

    for (const option of this.getSelectedOptions()) {
      // use displayName for 'pretty' query strings
      params.append(option.category, option.displayName);
    }

    return params.toString();
  }

  applyParams(params: ParsedUrlQuery) {
    const deserializedParams = deserializeFilterParams(params);
    return mergeFilterParams(deserializedParams, this);
  }

  copy(): FilterManager {
    return new FilterManager({ ...this.state });
  }
}

type DeserializedFilterOption = Pick<FilterOption, "displayName" | "category">;

function deserializeFilterParams(
  params: ParsedUrlQuery,
): Array<DeserializedFilterOption> {
  return Object.entries(params).reduce(
    (prev: DeserializedFilterOption[], [key, value]) => {
      if (!value) {
        return prev;
      }

      let params: string[] = [];

      if (Array.isArray(value)) {
        params = value;
      } else {
        const parsedParam: string | string[] = value;

        if (Array.isArray(parsedParam)) {
          params = parsedParam;
        } else {
          params = [parsedParam];
        }
      }

      return [
        ...prev,
        ...params.map((o) => ({
          displayName: o,
          category: key,
        })),
      ];
    },
    [],
  );
}

// try to apply the filter state from the URL to the existing filter structure
function mergeFilterParams(
  deserializedOptions: Array<DeserializedFilterOption>,
  filters: FilterManager,
): FilterManager {
  for (const option of deserializedOptions) {
    if (filters.hasCategory(option.category)) {
      const existingOption = filters
        .getOptions(option.category)
        .find((o) => o.displayName === option.displayName);

      if (existingOption) {
        filters.setOptionSelection(existingOption.value, true);
      }
    }
  }

  return filters;
}
