import { states } from "../types/coreApiMirror";
import { FilterManager } from "../types/filterManager";

export function getStateQueryString(state?: string) {
  if (!state) {
    return "";
  }

  // leveraging the filtermanager to serialize a single state
  const filters = new FilterManager().addCategoryOptions({
    items: states.slice(),
    categoryDisplayName: "State",
    categoryValue: "state",
    getValue: (s) => s,
    getDisplayName: (s) => s,
  });

  filters.setOptionSelection(state, true);

  const queryString = `?${filters.serialize()}`;
  return queryString;
}
