import { ReactNode } from "react";
import { Colors } from "../types/colors";
import { getClassName } from "@pairtreefamily/utils";

export type BlockBackgroundColor =
  | Extract<
      Colors.Solid,
      "white" | "teal" | "backgroundgray" | "green" | "darkblue"
    >
  | Extract<Colors.Gradient, "teal-green" | "purple-peach" | "teal-darkblue">;

export type BlockProps = {
  children: ReactNode;
  color?: BlockBackgroundColor;
  flushTop?: boolean;
  flushBottom?: boolean;
  fullWidth?: boolean;
  className?: string;
};

const backgroundMap: Record<BlockBackgroundColor, string> = {
  teal: "bg-teal text-white",
  green: "bg-green text-white",
  white: "bg-white",
  darkblue: "bg-darkblue",
  backgroundgray: "bg-backgroundgray",
  "teal-green": "bg-gradient-to-b from-teal to-green",
  "purple-peach": "bg-gradient-to-b from-purple to-peach",
  "teal-darkblue": "bg-gradient-to-b from-teal to-darkblue",
};

export function Block({ color = "white", ...props }: BlockProps) {
  const className = getClassName(
    "w-full",
    "flex flex-col",
    "items-center",
    !props.fullWidth && "px-5 sm:px-16",
    !props.flushTop && "pt-5 sm:pt-12",
    !props.flushBottom && "pb-5 sm:pb-12",
    backgroundMap[color],
    props.className,
  );

  return (
    <div className={className}>
      <div
        className={`${
          props.fullWidth ? "" : "container"
        } mx-auto w-full`.trim()}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Block;
