import React from "react";
import { ReactNode } from "react";
import { Colors } from "../types/colors";
import { getClassName } from "@pairtreefamily/utils";

export type ButtonStyle = "primary" | "secondary" | "pill";

export type ButtonColor = Extract<
  Colors.Solid,
  | "colorless"
  | "teal"
  | "green"
  | "purple"
  | "peach"
  | "lightgray"
  | "darkblue"
  | "lightgreen"
>;

const buttonColorMap: Record<ButtonColor, { [K in ButtonStyle]: string }> = {
  colorless: {
    primary: "",
    secondary: "",
    pill: "",
  },
  teal: {
    primary: "bg-teal hover:bg-teal-shade text-white",
    secondary: "border-teal hover:bg-teal hover:text-white",
    pill: "border-teal hover:bg-teal-tint hover:text-white",
  },
  green: {
    primary: "bg-green hover:bg-green-shade text-white",
    secondary: "border-green hover:bg-green hover:text-white",
    pill: "border-green hover:bg-green-tint hover:text-white",
  },
  purple: {
    primary: "bg-purple hover:bg-purple-shade text-white",
    secondary: "border-purple hover:bg-purple hover:text-white",
    pill: "border-purple hover:bg-purple-tint hover:text-white",
  },
  peach: {
    primary: "bg-peach hover:bg-peach-shade text-white",
    secondary: "border-peach hover:bg-peach hover:text-white",
    pill: "border-peach hover:bg-peach-tint hover:text-white",
  },
  lightgray: {
    primary: "bg-lightgray hover:bg-lightgray-shade text-white",
    secondary: "border-lightgray hover:bg-lightgray hover:text-white",
    pill: "border-lightgray text-black hover:bg-green-tint hover:text-white",
  },
  darkblue: {
    primary: "bg-darkblue hover:bg-darkblue-shade text-white",
    secondary: "border-darkblue hover:bg-darkblue hover:text-white",
    pill: "border-darkblue hover:bg-darkblue-tint hover:text-white",
  },
  lightgreen: {
    primary: "bg-lightgreen hover:bg-lightgreen-shade text-white",
    secondary: "border-lightgreen hover:bg-lightgreen hover:text-white",
    pill: "border-lightgreen hover:bg-lightgreen-tint hover:text-white",
  },
};

const buttonStyleMap: Record<ButtonStyle, string[]> = {
  primary: ["rounded-full", "font-semibold", "text-center", "px-8 py-4"],
  secondary: [
    "rounded-full",
    "font-semibold",
    "text-center",
    "px-8 py-4",
    "border-2",
  ],
  pill: ["rounded-full", "border-[1px]", "py-1 px-4", "max-h-10"],
};

export function getBaseButtonStyle(
  color: ButtonColor = "colorless",
  style: ButtonStyle = "primary",
) {
  return getClassName(
    ...buttonStyleMap[style],
    color != null && buttonColorMap[color][style],
  );
}

export type ButtonProps = Omit<
  React.ComponentPropsWithRef<"button">,
  "style" | "children"
> & {
  style?: ButtonStyle;
  color?: ButtonColor;
  children: ReactNode;
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ style, ...props }, ref) => {
    const { children } = props;
    const className = getClassName(
      getBaseButtonStyle(props.color, style),
      props.className,
    );

    return (
      <button {...props} className={className} ref={ref}>
        {children}
      </button>
    );
  },
);

Button.displayName = "Button";

export default Button;
